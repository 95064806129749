export enum PaymentMethods {
  Boleto = 'Boleto',
  Credito = 'Crédito',
  Debito = 'Débito',
  Pix = 'Pix',
  Dinheiro = 'Dinheiro',
  CreditoOffLine = 'Créd. (offline)',
  DebitoOffLine = 'Déb. (offline)',
}

export enum PaymentAcronym {
  Credito = 'C',
  Debito = 'D',
  Pix = 'P',
  Especie = 'M',
  CreditoOffLine = 'R',
  DebitoOffLine = 'E',
}

export enum PaymentFormsSchool {
  Credito = 'C',
  PixPresencial = 'P',
  PixRemoto = 'R',
}

export enum ProportionalTypes {
  Integral = 'I',
  Proporcional = 'P',
}

export enum PaymentTypeContract {
  CARTAO_CREDITO = 'C',
  BOLETO = 'B',
  PIX = 'P',
}

export enum PaymentBarbecueTypes {
  PIX_ANTECIPADO = 'P',
  BOLETO_ANTECIPADO = 'B',
  CREDITO_ANTECIPADO = 'C',
  NO_LOCAL = 'L',
  GRATIS = 'F'
}
