export enum BookingTypes {
  Avulso = 'O',
  Recorrente = 'R',
  Empresarial = 'E',
  Bloqueio = 'B',
  Churrasqueira = 'C',
  Torneio = 'T',
  JogoOficial = 'J',
  Cortesia = 'Y',
  Limpeza = 'L',
  Slot = 'S',
  Evento = 'V',
}

export enum BookingTypesB2B {
  Avulso = 'O',
  Mensal = 'R',
  Anual = 'Y',
}
