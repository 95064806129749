import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/services/base.service';
import { CacheService } from './cache.service';

@Injectable()
export class PaymentsService extends BaseService {
  constructor(private http: HttpClient, private cacheService: CacheService) {
    super();
  }

  getOptsPaymentFinancialSettlement() {
    return [
      {
        icon: 'icon-credit',
        name: 'Crédito',
        value: 'C',
      },
      {
        icon: 'icon-credit',
        name: 'Débito',
        value: 'D',
      },
      {
        icon: 'icon-pix',
        name: 'Pix',
        value: 'P',
      },
      {
        icon: 'icon-cash',
        name: 'Dinheiro',
        value: 'M',
      },
      {
        icon: 'icon-credit',
        name: 'Créd. (offline)',
        value: 'R',
      },
      {
        icon: 'icon-credit',
        name: 'Déb. (offline)',
        value: 'E',
      },
    ];
  }

  cancelAllStoneTransactionsByArenaID(): Observable<any> {
    const arenaID = this.cacheService.getUnit();
    return this.http.post<any>(`${this.paymentsAPI}/CancelAllStoneTransactionsByArenaID`, {
      arenaID,
    });
  }
}
